var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"25f2461b9b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {init} from '@sentry/nextjs'

import {eventIsFromGTM, eventWasInformed} from './sentry.config/beforeSendEvent'
import {getCommitHash} from './sentry.config/getCommitHash'
import {denyUrls, ignoreErrors} from './sentry.config/ignoreRules'

// Populated by Vercel Sentry Integration
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const commitHash = getCommitHash()

if (SENTRY_DSN) {
  init({
    dsn: SENTRY_DSN,
    release: 'justo-services@' + (commitHash || process.env.VERCEL_GIT_COMMIT_SHA || 'local'),
    environment: process.env.JUSTO_ENV,
    tracesSampleRate: 0.05,
    sampleRate: 0.2,
    beforeSend(event, hint) {
      const eventFromGTM = eventIsFromGTM(event, hint)
      return eventWasInformed(event) || eventFromGTM ? eventFromGTM : event
    },
    ignoreErrors: ignoreErrors,
    denyUrls: denyUrls
  })
}
